<template>
  <div class="page-login-desktop">
    <Table
        label="پیام ها"
        icon="mdi-message-bulleted"
        :table="table">
      <tr
          v-for="(item,index) in table.items"
          :key="index"
      >
        <td class="text-center">{{ index + 1 }}</td>
        <td class="text-center">{{ item.created_at }}</td>
        <td class="text-center">{{ item.user }}</td>
        <td class="text-center">{{ item.body }}</td>
        <td class="text-center">
          <v-chip color="success" text-color="white" v-if="item.status">
            خوانده شده
          </v-chip>
          <v-chip color="error" text-color="white" v-else>
            خوانده نشده
          </v-chip>
        </td>
        <td class="text-center">


        <ViewButton
            @click="item.actions.view"
        />

          <MessageButton
              @click="item.actions.replay"
          />

          <DeleteButton
              @click="item.actions.delete"
          />


        </td>
      </tr>
    </Table>

    <Pagination
        v-model="filters.skip"
        :key="filters.skip"
        :totalItems="table.totalCount"
        :perPage="filters.take"
        @change="(page)=>{fetchItems({skip: page})}"
    />

    <v-dialog v-model="viewMessage.modal" width="500">
      <v-card>
        <v-card-title class="text-body-1 ">
          <v-icon class="ml-2">
            mdi-message-bulleted
          </v-icon>
          مشاهده جزییات پیام
        </v-card-title>
        <v-card-text>
          {{ viewMessage.body }}
        </v-card-text>

        <v-card-actions class="d-flex align-center justify-space-between">
          <v-btn
              @click="viewMessage.modal=false"
              large text color="main">
            بستن
          </v-btn>

        </v-card-actions>
      </v-card>

    </v-dialog>

    <v-dialog v-model="deleteItem.modal" width="500">
      <v-card>
        <v-card-title class="text-body-1 ">
          <v-icon class="ml-2">
            mdi-information-outline
          </v-icon>
          حذف
        </v-card-title>
        <v-card-text>
          آیا از حذف این پیام مطمئن هستید ؟
        </v-card-text>

        <v-card-actions class="d-flex align-center justify-space-between">
          <v-btn
              @click="deleteItem.modal=false"
              large text color="main">
            لغو
          </v-btn>


          <v-btn
              :loading="deleteItem.loading"
              :disabled="deleteItem.loading"
              @click="destroyItem"
              elevation="0"
              large color="error">
            حذف
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>

    <NewMessage
        @onSubmit="fetchItems"
        :messageData="newMessage"
    />

  </div>
</template>

<script>

import Table from "@/components/Table"
import MessageButton from "@/components/MessageButton"
import DeleteButton from "@/components/DeleteButton"
import NewMessage from "@/components/NewMessage"
import ViewButton from "@/components/ViewButton"
import Pagination from "@/components/Pagination"
import {destroy, inbox, show} from "@Newfiling/module-message/src/api";
import {strLimit} from "@Newfiling/Services";

export default {
  name: 'DesktopLogin',

  components: {
    Table,
    Pagination,
    NewMessage,
    MessageButton,
    DeleteButton,
    ViewButton,
  },

  data() {
    return {
      filters: {
        take: 10,
        skip: 0,
      },

      table: {
        headers: [
          '#',
          'تاریخ ثبت',
          'کاربر',
          'عنوان',
          'وضعیت',
          'عملیات',
        ],
        items: [],
        totalCount: 0,
        loading: false,
      },

      deleteItem: {
        modal: false,
        loading: false,
        id: null,
      },

      viewMessage: {
        modal: false,
        body: "",
      },

      newMessage: {
        modal: false,
        phoneNumber: null,
      }
    }
  },

  beforeMount() {
    this.$store.commit('Breadcrumb/setBreadcrumb', [
      {
        text: 'پنل مدیریت نیوفایل',
        disabled: false,
        href: '/panel/dashboard',
      },
      {
        text: 'پیام های دریافتی کاربران',
        disabled: true,
        href: '/panel/messages/sent',
      },
    ])
  },

  mounted() {
    this.fetchItems();
  },

  methods: {
    async fetchItems(filters = {}) {
      this.filters = {
        ...this.filters,
        ...filters
      }
      this.table.loading = true;
      try {
        const res = (await inbox({
          ...this.filters,
          skip: this.filters.skip * this.filters.take,
        }))?.data || {
          recordcount: 0,
          list: [],
        };

        console.log({res})

        this.table.items = res?.list.map(item => {
          return {
            created_at: item.solarDateofRegistration,
            user: item.sendMobile.substring(1),
            title: strLimit(item.title, 10),
            body: strLimit(item.messagetext, 10),
            status: item.messagestatus,
            actions: {

              view: async () => {
                await show(item.id);
                this.viewMessage = {
                  ...this.viewMessage,
                  modal: true,
                  body: item.messagetext,
                }
                await this.fetchItems();
              },

              delete: () => {
                this.deleteItem = {
                  ...this.deleteItem,
                  id: item.id,
                  modal: true,
                  loading: false,
                }
              },

              replay: () => {
                this.newMessage = {
                  modal: true,
                  phoneNumber: item.sendMobile.substring(1),
                }
              },
            }
          }
        });
        this.table.totalCount = res?.recordcount;
      } catch (e) {
        console.log(e)
      }
      this.table.loading = false;
    },

    async destroyItem() {
      this.deleteItem.loading = true;
      try {
        await destroy(this.deleteItem.id);
        this.$toast.success('پیام مورد نظر با موفقیت حذف شد.');
        this.deleteItem = {
          ...this.deleteItem,
          modal: false,
          loading: false,
          id: null,
        }
        await this.fetchItems();
      } catch (e) {
        console.log(e)
      }
      this.deleteItem.loading = false;
    },
  }

}
</script>
